import React from 'react';
import { twMerge } from 'tailwind-merge';

type HeaderWrapperProps = {
    children: React.ReactNode | React.ReactNode[] | null,
    subHeader?: React.ReactNode | React.ReactNode[] | null,
    headerClassName?: string,
}
const HeaderWrapper = ({
    children = null,
    subHeader = null,
    headerClassName = '',
}: HeaderWrapperProps) => (
    <>
        <header className="shadow-header bg-header-background border-b border-header-border z-40 px-safe">
            <nav className="max-w-page mx-auto px-4 lg:px-16">
                <div className={twMerge(
                    'flex justify-between items-center h-16 md:h-20',
                    headerClassName,
                )}
                >
                    {children}
                </div>
            </nav>
        </header>
        {subHeader && (
            <div className="bg-header-background shadow-header z-30 px-safe">
                <div className="max-w-page mx-auto py-3 px-4 lg:px-16">
                    {subHeader}
                </div>
            </div>
        )}
    </>
);

export default HeaderWrapper;
