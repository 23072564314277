export function isObject(item) {
    return (item && typeof item === 'object');
}

export function mergeDeep<T = unknown>(...objects: T[]) {
    return objects.reduce((prev, obj) => {
        Object.keys(obj as any).forEach((key) => {
            const pVal = prev[key];
            const oVal = obj[key];

            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal.concat(...oVal);
            } else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal);
            } else {
                prev[key] = oVal;
            }
        });

        return prev;
    }, {});
}

export const getByPath = (object: object | null, path: string): any | null => {
    if (object == null) { // null or undefined
        return object;
    }
    const parts = path.split('.');

    return parts.reduce((obj, key) => obj?.[key], object);
};

export const removeEmptyFields = (obj: Record<string, unknown>) => (
    Object.keys(obj).reduce((acc, key) => (
        obj[key] === undefined ? { ...acc } : { ...acc, [key]: obj[key] }
    ), {})
);

export const createURLSearchParams = (params: Record<string, unknown>) => {
    const sanitizeParams = removeEmptyFields(params);
    const queryParams = new URLSearchParams(sanitizeParams).toString();

    if (!queryParams) return '';

    return `?${queryParams}`;
};

export const isEmpty = (value: unknown) => (
    value === null
    || value === undefined
    || value === ''
    || (typeof value === 'number' && Number.isNaN(value))
    || (Array.isArray(value) && value.length === 0)
    || (typeof value === 'object' && Object.keys(value).length === 0 && value.constructor === Object)
);
