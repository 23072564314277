import { serviceApi } from '@hydrogen/elements.core.rest.transport';
import { serviceType } from '@hydrogen/elements.core.rest.types';
import { useQuery } from 'react-query';

export type SystemSettingsResponse = serviceType['schemas']['SystemSetting'];

type SystemSettingsParams = {
    group: string // replace with enum?
    key: string // replace with enum?
}

export function useSystemSettings({ group, key }: SystemSettingsParams, eOpts = {}) {
    return useQuery<SystemSettingsResponse>(
        ['systemSettings', group, key],
        () => serviceApi()
            .get(`v1.0/settings/${group}/${key}`)
            .then(({ data }) => data),
        {
            enabled: typeof group === 'string'
                && typeof key === 'string',
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 0,
            ...eOpts,
        },
    );
}
