export const PORTFOLIO_COLORS = {
    red: 'bg-various-red',
    orange: 'bg-various-orange',
    amber: 'bg-various-amber',
    yellow: 'bg-various-yellow',
    lime: 'bg-various-lime',
    green: 'bg-various-green',
    emerald: 'bg-various-emerald',
    teal: 'bg-various-teal',
    cyan: 'bg-various-cyan',
    sky: 'bg-various-sky',
    blue: 'bg-various-blue',
    indigo: 'bg-various-indigo',
    violet: 'bg-various-violet',
    purple: 'bg-various-purple',
    fuchsia: 'bg-various-fuchsia',
    pink: 'bg-various-pink',
    rose: 'bg-various-rose',
};
