import React, { useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import IconButton from '@hydrogen/elements.ui.components.icon-button';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import NavListDialog from './NavListDialog';
import ProfileInfo from './ProfileInfo';
import { NavLinkType, ProfileInfoType, MenuItemType } from '../types';

type MobileNavBarProps = {
    navigations?: NavLinkType[],
    profileInfo?: ProfileInfoType | null
    menuItems?: MenuItemType[]
    logoSrc?: string | null,
    logoLink?: string,
    onClickLogo?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    logoSvg?: React.ReactElement | null,
}
const MobileNavBar = ({
    navigations = [],
    menuItems = [],
    profileInfo = null,
    logoSrc = null,
    logoLink = '',
    logoSvg = null,
    onClickLogo,
}: MobileNavBarProps) => {
    const [more, setMore] = useState(false);

    if (!navigations?.length && !menuItems?.length) return null;

    return (
        <>
            <IconButton
                onClick={() => setMore(true)}
            >
                <>
                    <span className="sr-only">Open main menu</span>
                    <Icon
                        icon={more ? XIcon : MenuIcon}
                        className="block"
                        size={IconsSizes.medium}
                    />
                </>
            </IconButton>
            <NavListDialog
                logoSrc={logoSrc}
                logoSvg={logoSvg}
                onClickLogo={onClickLogo}
                logoLink={logoLink}
                show={more}
                navigations={navigations}
                onClose={() => setMore(false)}
            >
                {
                    profileInfo && (
                        <ProfileInfo
                            classNamePanel="bg-transparent"
                            profileInfo={profileInfo}
                            menuItems={menuItems}
                        />
                    )
                }
            </NavListDialog>
        </>
    );
};

export default MobileNavBar;
