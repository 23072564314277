import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
    XIcon,
} from '@heroicons/react/outline';
import { classNames } from '@hydrogen/elements.ui.utils';
import Icon from '@hydrogen/elements.ui.icon';

import { getLink } from '@hydrogen/elements.ui.configs';
import Badge from '@hydrogen/elements.ui.components.badge';
import Logo from './Logo';
import { NavLinkType } from '../types';

type NavListDialogProps = {
    navigations: NavLinkType[],
    show: boolean
    onClose: () => void
    onClickLogo?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    children: React.ReactNode | React.ReactNode[] | null,
    logoSrc?: string | null,
    logoText?: string,
    logoLink?: string,
    logoSvg?: React.ReactElement | null,

}

const NavListDialog = (props: NavListDialogProps) => {
    const Link = getLink();

    return (
        <Transition.Root show={props.show} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 flex z-40" aria-label="Navigation" onClose={props.onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-absolute-800 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <div
                        className="relative flex-1 flex flex-col rounded-t-md shadow-lg overflow-hidden mt-8 max-w-lg mx-auto w-full bg-white"
                    >
                        <div className="flex-shrink-0 flex items-center justify-between p-4 shadow-sm ">
                            <span className="flex w-auto">
                                <Logo
                                    onClick={props.onClickLogo}
                                    logoText={props.logoText}
                                    logoSrc={props.logoSrc}
                                    logoSvg={props.logoSvg}
                                    link={props.logoLink}
                                    size="h-7"
                                />
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <button
                                    type="button"
                                    className="ms-1 flex items-center justify-center h-8 w-8 rounded-md text-secondary-500 hover:text-primary-500 bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    onClick={() => props.onClose()}
                                >
                                    <span className="sr-only">Close sidebar</span>
                                    <Icon icon={XIcon} className="" />
                                </button>
                            </Transition.Child>
                        </div>
                        <div className="flex-1 h-0 mt-1 overflow-y-auto bg-neutral-50">
                            <nav className="p-4 space-y-1 bg-white">
                                {props.navigations.map((item) => (
                                    <Link
                                        onClick={() => props.onClose()}
                                        to={item.link}
                                        key={`n-${item.label}`}
                                        className={classNames(
                                            globalThis.location.href.includes(item.link)
                                                ? 'bg-neutral-100'
                                                : 'hover:bg-neutral-100',
                                            'block text-primary-500 rounded-md px-2.5 py-1.5 text-base font-bold transition',
                                        )}
                                    >

                                        {item.label}
                                        {item.info && <Badge className="ms-2" type="accent" title={<>{item.info}</>} />}

                                    </Link>

                                ))}
                            </nav>

                            <div className="pb-3 border-t border-neutral-200 bg-neutral-50">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    );
};

export default NavListDialog;
