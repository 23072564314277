import { startOfYear, sub } from 'date-fns';
import { convertToISOString } from '@hydrogen/elements.core.i18n';
import { TimeIntervalEnum } from '@hydrogen/elements.core.types';

export const dateRangesDefault = [
    {
        from: convertToISOString(sub(startOfYear(new Date()), { days: 1 }), 'date'),
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.YTD,
    },
    {
        from: null,
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.SINCE_INCEPTION,
    },
    {
        from: convertToISOString(sub(new Date(), { months: 1 }), 'date'),
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.MONTHS1,
    },
    {
        from: convertToISOString(sub(new Date(), { months: 3 }), 'date'),
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.MONTHS3,
    },
    {
        from: convertToISOString(sub(new Date(), { months: 6 }), 'date'),
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.MONTHS6,
    },
    {
        from: convertToISOString(sub(new Date(), { years: 1 }), 'date'),
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.YEARS1,
    },
    {
        from: convertToISOString(sub(new Date(), { years: 3 }), 'date'),
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.YEARS3,
    },
    {
        from: convertToISOString(sub(new Date(), { years: 5 }), 'date'),
        to: convertToISOString(new Date(), 'date'),
        key: TimeIntervalEnum.YEARS5,
    },
];
