import React from 'react';
import { Outlet, Navigate } from '@hydrogen/elements.core.router';
import BaseLayout from '@hydrogen/elements.ui.layouts.base';
import Footer from '@hydrogen/elements.widgets.wealth.footer';
import Header from '@hydrogen/elements.widgets.wealth.header-base';
import { useSecure } from '@hydrogen/elements.auth.identity-server';
import { t } from '@hydrogen/elements.core.i18n';
import { BreadcrumbDynamicTitle } from '@hydrogen/elements.widgets.wealth.breadcrumbs';
import { useInstrumentDetails } from '@hydrogen/elements.data.wealth.rest.use-instrument-details';
import { useContact } from '@hydrogen/elements.data.wealth.rest.use-contact';
import { LoaderBase } from './loader-base';

const home = '/dashboard';

const MainHeader = () => {
    const { logout } = useSecure();

    return (
        <Header
            onLogout={logout}
            navigations={() => ([
                {
                    label: t('nav.dashboard'),
                    link: '/dashboard',
                },
                {
                    label: t('nav.clients'),
                    link: '/clients',
                },
                // {
                //     label: t('nav.productivity'),
                //     link: '/productivity',
                // },
                {
                    label: t('nav.analytics'),
                    link: '/analytics',
                },
                {
                    label: t('nav.investments'),
                    link: '/investments',
                },
                {
                    label: t('nav.instruments'),
                    link: '/instruments',
                },
                // {
                //     label: t('nav.orders'),
                //     link: '/orders',
                // },
                {
                    label: t('nav.insights'),
                    link: '/insights',
                },
            ]
            )}
        />
    );
};

const DashboardRoutes = React.lazy(() => import('./routes/dashboard'));
const AnalyticsRoutes = React.lazy(() => import('./routes/analytics'));
// const ProductivityRoutes = React.lazy(() => import('./routes/productivity'));
const ClientRoutes = React.lazy(() => import('./routes/clients'));
const ClientDetailRoutes = React.lazy(() => import('./routes/client-detail'));
const NewInvestmentRoutes = React.lazy(() => import('./routes/new-investment'));
const ModifyPortfolioRoutes = React.lazy(() => import('./routes/modify-portfolio'));
const ReviewPortfolioRoutes = React.lazy(() => import('./routes/review-portfolio'));
const InvestmentsRoutes = React.lazy(() => import('./routes/investments'));
const InstrumentsRoutes = React.lazy(() => import('./routes/instruments'));
const InstrumentDetailRoutes = React.lazy(() => import('./routes/instrument-detail'));
// const OrdersRoutes = React.lazy(() => import('./routes/orders'));
const FooterRoutes = React.lazy(() => import('./routes/footer'));
const InsightsRoutes = React.lazy(() => import('./routes/insights'));
const OnboardingRoutes = React.lazy(() => import('./routes/onboarding'));
const ReportRoutes = React.lazy(() => import('./routes/report'));
const CustodianOnboarding = React.lazy(() => import('./routes/custodian-onboarding'));

export const routingConfig = [
    {
        Component: BaseLayout,
        props: {
            header: <MainHeader />,
            children: <Outlet />,
            footer: <Footer />,
        },
        url: '/',
        breadcrumb: 'dashboard',
        children: [
            {
                url: '/analytics/*',
                breadcrumb: () => t('nav.analytics'),
                Component: AnalyticsRoutes,
                fallback: (<LoaderBase />),
            },
            {
                url: 'investments',
                breadcrumb: () => t('nav.investments'),
                Component: InvestmentsRoutes,
                fallback: (<LoaderBase />),
            },
            {
                url: 'instruments',
                breadcrumb: () => t('nav.instruments'),
                Component: InstrumentsRoutes,
                fallback: (<LoaderBase />),
            },
            {
                url: 'instruments/:instrumentId/',
                breadcrumb: (match) => {
                    const { params: { instrumentId } } = match;

                    return (
                        <BreadcrumbDynamicTitle
                            dataSource={useInstrumentDetails}
                            dataSourceParams={{
                                instrumentId: Number(instrumentId),
                                performanceRange: 'Custom Range',
                            }}
                            getTitle={(data) => data?.name.instrumentName}
                        />
                    );
                },
                Component: InstrumentDetailRoutes,
                fallback: (<LoaderBase />),
            },
            // {
            //     url: 'orders',
            //     breadcrumb: () => t('nav.orders'),
            //     Component: OrdersRoutes,
            //     fallback: (<LoaderBase />),
            // },
            {
                url: '/footer/*',
                Component: FooterRoutes,
                fallback: (<LoaderBase />),
            },
            {
                url: 'insights/*',
                breadcrumb: () => t('nav.insights'),
                Component: InsightsRoutes,
                fallback: (<LoaderBase />),
            },
            {
                url: '/dashboard/*',
                Component: DashboardRoutes,
                fallback: (<LoaderBase />),
            },
            // {
            //     url: '/productivity/*',
            //     breadcrumb: () => t('nav.productivity'),
            //     Component: ProductivityRoutes,
            //     fallback: (<LoaderBase />),
            // },
            {
                url: '/clients/',
                breadcrumb: () => t('nav.clients'),
                Component: ClientRoutes,
                fallback: (<LoaderBase />),
            },
            {
                Component: () => (
                    <div className="flex-1">
                        <Navigate replace to={home} />
                    </div>
                ),
                url: '',
            },
        ],
    },
    // {
    //     url: '/clients/onboarding/:workflowId/*',
    //     Component: OnboardingRoutes,
    //     fallback: (<LoaderBase />),
    // },
    // {
    //     url: '/new-onboarding/*',
    //     index: true,
    //     Component: CustodianOnboarding,
    //     fallback: (<LoaderBase />),
    // },
    {
        url: 'report/',
        Component: ReportRoutes,
        fallback: (<LoaderBase />),
    },
    {
        url: '/clients/:clientId/investments/:investmentId/modify/*',
        Component: ModifyPortfolioRoutes,
        fallback: (<LoaderBase />),
    },
    {
        url: '/clients/:clientId/investments/:investmentId/review/*',
        Component: ReviewPortfolioRoutes,
        fallback: (<LoaderBase />),
    },
    {
        url: '/clients/:clientId/new-investments/*',
        Component: NewInvestmentRoutes,
        fallback: (<LoaderBase />),
    },
    {
        url: '/clients/:clientId/*',
        Component: ClientDetailRoutes,
        fallback: (<LoaderBase />),
        breadcrumb: (match) => {
            const { params: { clientId } } = match;

            return (
                <BreadcrumbDynamicTitle
                    dataSource={useContact}
                    dataSourceParams={[[+clientId]]}
                    getTitle={(data) => data?.[0]?.profile?.personalInformation?.displayName}
                />
            );
        },
    },
    {
        Component: () => <div>404</div>,
        url: '*',
    },
];
