import { useEffect, useState, useMemo } from 'react';
import { customApi } from '@hydrogen/elements.core.rest.transport';
import { useMemberId } from '@hydrogen/elements.data.wealth.app.use-member-id';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useSystemSettings } from '@hydrogen/elements.data.wealth.rest.use-system-settings';
import { useSchemaListsKeys } from '@hydrogen/elements.data.wealth.rest.use-schema-lists-keys';
import { SchemaListsKeyType } from '@hydrogen/elements.core.contracts';
import { MemberContacts } from './types';
import useCurrency from './hooks/use-currency';

const STORAGE_KEY = 'MEMBER_CONTACTS';

const defaultValue = { version: '', contacts: [] };

export const getCache = (): MemberContacts => {
    try {
        return JSON.parse(localStorage.getItem(STORAGE_KEY) || '') || defaultValue;
    } catch {
        return { version: '', contacts: [] };
    }
};

export function useContact(contactIds?: number[]) {
    const cacheValue = useMemo(() => (getCache()), []);
    const memberId = useMemberId();
    const { language } = useLanguage();
    const [isLoading, setIsLoading] = useState(!cacheValue?.version);
    const [error, setError] = useState('');
    const [cache, setCache] = useState<MemberContacts>(cacheValue);

    const {
        data: currenciesList = [],
        isLoading: isLoadingCurrencies,
    } = useSchemaListsKeys('currency');

    const {
        data: settings,
        isLoading: isLoadingSettings,
    } = useSystemSettings({
        group: 'General',
        key: 'DefaultCurrencyId',
    });

    const {
        data: defaultCurrencyData,
        isLoading: defaultCurrencyDataLoading,
    } = useCurrency(settings?.Value);

    const isApisLoading = isLoadingSettings || isLoadingCurrencies || defaultCurrencyDataLoading;

    useEffect(() => {
        if (memberId && !isApisLoading && defaultCurrencyData) {
            setIsLoading(true);
            customApi()
                .get(`v1.0/members/${memberId}/contacts`, {
                    params: {
                        version: cache.version,
                        includeAvatars: false,
                        language,
                    },
                })
                .then(async (response) => {
                    if (response.data) {
                        const { contacts = [] } = response.data as MemberContacts;

                        const profileCurrencies = contacts.map(({ profile }) => (
                            profile.investmentInformation.reportingCurrency.value
                        )).filter(Boolean);

                        const currencyCollection = [...new Set(profileCurrencies)]
                            .map((cId) => ((currenciesList as (Partial<
                                SchemaListsKeyType> & { key: string })[]
                            ) .find(({ id }) => id === cId)))
                            .filter(Boolean);

                        // default currency object
                        currencyCollection.push({
                            id: defaultCurrencyData.Id,
                            key: defaultCurrencyData.CurrencyCode,
                            label: defaultCurrencyData.Name,
                        });

                        const adaptedData = contacts.map((item) => {
                            const { key } = currencyCollection.find((c: any) => (
                                c.id === (item.profile.investmentInformation.reportingCurrency.value
                                        || defaultCurrencyData.Id)
                            )) || {};

                            return {
                                ...item,
                                profile: {
                                    ...item.profile,
                                    currencyCode: key ?? item.profile.currencyCode,
                                },
                            };
                        });
                        const finalData = {
                            ...response.data,
                            contacts: adaptedData,
                        };

                        localStorage.setItem(STORAGE_KEY, JSON.stringify(finalData));
                        setCache(finalData);
                    }
                })
                .catch((e) => setError(e.message))
                .finally(() => setIsLoading(false));
        }
    }, [memberId, language, isApisLoading]);

    const data = contactIds
        ? cache?.contacts?.filter((c) => contactIds?.includes(c.profile.id))
        : cache?.contacts;

    return { isLoading, error, data };
}
