import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import { BellIcon } from '@heroicons/react/solid';
import PopOver, { PopoverSizes } from '@hydrogen/elements.ui.components.popover';
import IconButton, { IconButtonTypes } from '@hydrogen/elements.ui.components.icon-button';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { LinkList, LinkListItem, LinkListItemVariants } from '@hydrogen/elements.ui.components.link-list';
import { Empty } from '@hydrogen/elements.ui.components.content-box';
import LinkButton from '@hydrogen/elements.ui.components.link-button';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { t } from '@hydrogen/elements.core.i18n';
import { NotificationItemType } from '../types';

export type NotificationButtonProps = {
    title?: string
    notifications?: NotificationItemType[]
    markAsRead?: (id?: number) => void
    isLoading?: boolean
    variant?: IconButtonTypes
}

const NotificationButton = ({
    title = 'Notifications',
    notifications = [],
    markAsRead,
    isLoading,
    variant = IconButtonTypes.header,
}: NotificationButtonProps) => {
    const handleRead = (notificationId: number) => {
        if (markAsRead) markAsRead(notificationId);
    };

    const handleMarkAll = () => {
        if (markAsRead) markAsRead();
    };

    return (
        <div className="z-30 block">
            <SkeletonWrapper
                isLoading={isLoading ?? false}
                Skeleton={<SkeletonLine height={8} width={8} />}
            >
                <PopOver
                    size={PopoverSizes.large}
                    buttonContent={(
                        <>
                            <IconButton visual type={variant}>
                                <span
                                    className={
                                        classNames(
                                            notifications.length === 0
                                                ? 'hidden'
                                                : 'absolute w-2.5 h-2.5 bg-danger-500 rounded-full border-2 border-header-background -top-1 -right-1',
                                        )
                                    }
                                >
                                    <span className="sr-only">
                                        {`${notifications.length} ${t('common.new')}`}
                                    </span>
                                </span>
                                <span className="sr-only">
                                    {title}
                                </span>
                                <Icon icon={BellIcon} size={IconsSizes.medium} />
                            </IconButton>

                        </>
                    )}
                >
                    <p className="px-4 pt-2 pb-3 font-bold border-b border-content-separator text-primary-500">
                        {title}
                    </p>
                    <div className="divide-y divide-content-separator" style={{ maxHeight: '25rem', overflowY: 'auto' }}>
                        <LinkList>
                            {
                                notifications.length === 0
                                    ? (
                                        <div className="p-4">
                                            <Empty>
                                                <>{t('notifications.no-new-notifications')}</>
                                            </Empty>
                                        </div>
                                    )
                                    : notifications.map((item) => (
                                        <LinkListItem
                                            variant={LinkListItemVariants.compact}
                                            as={item?.href === '#' ? 'div' : 'button'}
                                            to={item?.href === '#' ? undefined : item.href}
                                            // as="button"
                                            // to={item?.href}
                                            key={item.id}
                                            onClick={() => handleRead(item.id)}
                                        >
                                            <div>
                                                <p className="text-sm font-medium text-primary-500">{item.title}</p>
                                                <p
                                                    className="text-secondary-500 text-sm [&_a]:underline"
                                                    // eslint-disable-next-line react/no-danger
                                                    dangerouslySetInnerHTML={
                                                        { __html: item.description }
                                                    }
                                                />
                                            </div>
                                        </LinkListItem>
                                    ))
                            }

                        </LinkList>
                    </div>
                    {notifications.length > 0
                        ? (
                            <div
                                className="flex justify-center py-2 -mb-1 border-t border-functionbar-border bg-functionbar-background rounded-b-content"
                            >
                                <LinkButton onClick={handleMarkAll}>
                                    <>{t('notifications.mark-all-as-read') || 'mark all as read'}</>
                                </LinkButton>
                            </div>
                        )
                        : <></>}
                </PopOver>
            </SkeletonWrapper>
        </div>
    );
};

export default NotificationButton;
