import React, { useState } from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import { AvatarCircle } from '@hydrogen/elements.ui.components.avatar';
import LinkButton from '@hydrogen/elements.ui.components.link-button';
import { AvatarSizesEnum } from '@hydrogen/elements.core.contracts';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from '@heroicons/react/outline';
import { RadioGroup } from '@headlessui/react';
import Icon from '@hydrogen/elements.ui.icon';
import { v4 as uuidv4 } from 'uuid';
import {
    SkeletonWrapper,
    SkeletonLine,
    SkeletonCircle,
} from '@hydrogen/elements.ui.components.skeleton';
import ProfileSeparator from './ProfileSeparator';
import { ProfileInfoType, MenuItemType } from '../types';

type PanelProps = {
    type?: 'main' | 'child';
    children?: React.ReactNode | React.ReactNode[] | null;
    className?: string;
    shown?: boolean;
};

const ProfilePanel = (props: PanelProps) => {
    const panelTypeClasses = {
        main: {
            shown: 'opacity-0',
            hidden: 'opacity-100',
        },
        child: {
            shown: 'opacity-100',
            hidden: 'opacity-0',
        },
    };

    const type = props.type || 'main';

    return (
        <div
            className={classNames(
                props.className,
                'rounded-content shadow-popover py-1 bg-white ring-1 ring-neutral-200 ring-opacity-50 w-full flex-shrink-0 self-start transition-opacity',
                props.shown ? panelTypeClasses[type].shown : panelTypeClasses[type].hidden,
            )}
        >
            {props.children}
        </div>
    );
};

type ProfileInfoProps = {
    classNamePanel?: string;
    avatarSize?: keyof typeof AvatarSizesEnum;
    profileInfo?: ProfileInfoType | null;
    menuItems?: MenuItemType[];
    closePopover?: () => void;
};
const ProfileInfo = (props: ProfileInfoProps) => {
    const [childOptions, setChildOptions] = useState<MenuItemType | null>(null);

    const spacingClasses = 'px-3 py-2.5';
    const linkButtonClasses = 'flex w-full rounded-button items-center mb-1 font-medium gap-2 text-sm transition text-start text-primary-500 hover:bg-neutral-100 hover:text-primary-500 disabled:opacity-50 disabled:cursor-not-allowed';

    return (
        <div className="flex flex-col flex-shrink-0 p-2 pb-5 -mx-1 -mt-4 overflow-hidden text-start">
            <div
                className={classNames(
                    'flex transition-all relative',
                    childOptions ? '-translate-x-full rtl:translate-x-full' : 'translate-x-0',
                )}
            >
                <ProfilePanel shown={!!childOptions} className={props.classNamePanel}>
                    <>
                        {props?.profileInfo && (
                            <div className="relative flex items-center gap-4 px-4 pt-2 pb-3 mb-1 border-b border-content-separator text-secondary-500">
                                <SkeletonWrapper
                                    Skeleton={<SkeletonCircle size={10} />}
                                    isLoading={props.profileInfo.isLoading}
                                >
                                    <AvatarCircle
                                        size={props.avatarSize}
                                        alt={props.profileInfo.name}
                                        src={props.profileInfo.src}
                                        withAltBackground={props.profileInfo.withAltBackground}
                                    />
                                </SkeletonWrapper>
                                <SkeletonWrapper
                                    Skeleton={(
                                        <div className="flex gap-1 w-[60%]">
                                            <SkeletonLine width={18} className="flex-1" />
                                            <SkeletonLine width={18} className="flex-1" />
                                        </div>
                                    )}
                                    isLoading={props.profileInfo.isLoading}
                                >
                                    <div className="grid">
                                        <p className="text-sm font-bold truncate text-primary-500">
                                            {props.profileInfo.name}
                                        </p>
                                        {props.profileInfo?.email && (
                                            <p className="text-sm truncate">
                                                {props.profileInfo.email}
                                            </p>
                                        )}
                                    </div>
                                </SkeletonWrapper>
                            </div>
                        )}
                    </>
                    <nav
                        className="flex-shrink-0 block mt-1 mb-1"
                        aria-label="Account and settings"
                    >
                        <ul>
                            {props?.menuItems?.map?.((item) => (
                                <React.Fragment key={uuidv4()}>
                                    <li className="mx-2">
                                        <LinkButton
                                            as={item.type === 'link' ? 'a' : 'button'}
                                            href={item.link}
                                            className={classNames(
                                                linkButtonClasses,
                                                spacingClasses,
                                            )}
                                            onClick={() => {
                                                if (item?.options?.length) {
                                                    setChildOptions(item);

                                                    return;
                                                }
                                                item?.onClick?.();
                                                if (item.shouldClosePopoverOnClick) {
                                                    props.closePopover?.();
                                                }
                                            }}
                                        >
                                            <>
                                                {item.icon || null}
                                                <div className="flex flex-col flex-wrap flex-1">
                                                    <span>{item.label}</span>
                                                    <span className="text-xs text-secondary-500">
                                                        {item.subLabel}
                                                    </span>
                                                </div>
                                                {item?.options && (
                                                    <Icon
                                                        icon={ChevronRightIcon}
                                                        className="text-secondary-500 ms-auto rtl:rotate-180"
                                                    />
                                                )}
                                            </>
                                        </LinkButton>
                                    </li>
                                    {item.withSeparator && (
                                        <ProfileSeparator as="li" margin="my-2" />
                                    )}
                                </React.Fragment>
                            ))}
                        </ul>
                    </nav>
                </ProfilePanel>
                <ProfilePanel type="child" shown={!!childOptions} className={props.classNamePanel}>
                    <div className="py-1 mb-2 -mt-1 border-b bg-functionbar-background border-functionbar-border rounded-t-content">
                        <div className={classNames('mx-2 mt-1 mb-1 relative')}>
                            <LinkButton
                                as="button"
                                className={classNames(
                                    'flex w-full rounded-button items-center mb-1 font-medium',
                                    'text-sm transition text-center justify-center text-primary-500',
                                    'px-1 py-1',
                                )}
                                onClick={() => setChildOptions(null)}
                            >
                                <Icon
                                    icon={ChevronLeftIcon}
                                    className="absolute start-0 rtl:rotate-180"
                                />
                                <>{childOptions?.label}</>
                            </LinkButton>
                        </div>
                    </div>
                    <RadioGroup
                        value={childOptions?.value}
                        onChange={(value) => {
                            setChildOptions(null);
                            childOptions?.onChange?.(value);
                        }}
                    >
                        <RadioGroup.Label className="sr-only">Available languages</RadioGroup.Label>
                        <div className="flex-shrink-0 block mx-2 mt-2 mb-1">
                            {childOptions?.options?.map((item) => (
                                <RadioGroup.Option
                                    key={uuidv4()}
                                    value={item}
                                    className={({ checked, active }) => classNames(
                                        checked
                                            ? 'bg-brand-200 text-brand-700 hover:text-primary-500'
                                            : 'text-primary-500',
                                        active ? 'bg-brand-200' : '',
                                        spacingClasses,
                                        linkButtonClasses,
                                        'relative block cursor-pointer focus:outline-none transition group',
                                    )}
                                    dir={item?.dir as string}
                                >
                                    {({ active, checked }) => (
                                        <>
                                            <div
                                                className="flex items-center justify-between w-full space-x-2"
                                                dir={item?.dir as string}
                                            >
                                                <div className="text-sm">
                                                    <RadioGroup.Label
                                                        as="p"
                                                        className={classNames(
                                                            item.dir === 'rtl'
                                                                ? 'text-end'
                                                                : 'text-start',
                                                        )}
                                                    >
                                                        <span className="block font-medium">
                                                            {item.label}
                                                        </span>
                                                        {item.subLabel && (
                                                            <span
                                                                className={classNames(
                                                                    !checked
                                                                        && 'text-secondary-500',
                                                                    'block text-xs font-normal group-hover:text-secondary-500',
                                                                )}
                                                            >
                                                                {item.subLabel}
                                                            </span>
                                                        )}
                                                    </RadioGroup.Label>
                                                </div>
                                                {checked && <Icon icon={CheckIcon} />}
                                            </div>
                                            <div
                                                className={classNames(
                                                    active ? '' : '',
                                                    checked ? '' : '',
                                                    'absolute -inset-px pointer-events-none',
                                                )}
                                                aria-hidden="true"
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                </ProfilePanel>
            </div>
        </div>
    );
};

export default ProfileInfo;
