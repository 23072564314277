import { useQuery } from 'react-query';
import { serviceApi } from '@hydrogen/elements.core.rest.transport';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { TCurrency } from '@hydrogen/elements.core.contracts';

const useCurrency = (id: number | string | undefined | null, eOpts = {}) => {
    const { language } = useLanguage();

    return useQuery<TCurrency>(
        ['currency', id, language],
        () => serviceApi()
            .get(
                `/v1.0/currencies/${id}`,
                { params: { language } },
                { 'Accept-Language': language },
            )
            .then(({ data }) => data),
        {
            keepPreviousData: true,
            enabled: !!id,
            ...eOpts,
        },
    );
};

export default useCurrency;
