import React from 'react';
import Popover from '@hydrogen/elements.ui.components.popover';
import { Avatar } from '@hydrogen/elements.ui.components.avatar';
import { classNames } from '@hydrogen/elements.ui.utils';
import {
    SkeletonWrapper, SkeletonLine,
} from '@hydrogen/elements.ui.components.skeleton';
import { AvatarSizesEnum } from '@hydrogen/elements.core.contracts';
import ProfileInfo from './ProfileInfo';
import { MenuItemType, ProfileInfoType } from '../types';

type ProfileMenuProps = {
    profileInfo?: ProfileInfoType | null,
    menuItems?: MenuItemType[],
    withProfileInfo?: boolean,
    isFavorite?: boolean,
    origin?: 'left' | 'right',
    avatarSize?: keyof typeof AvatarSizesEnum,
    popoverButtonContent?: React.ReactElement | null,
}

const ProfileMenu = ({
    profileInfo = null,
    menuItems = [],
    withProfileInfo = true,
    isFavorite = false,
    origin = 'right',
    avatarSize = AvatarSizesEnum.large,
    popoverButtonContent = null,
}: ProfileMenuProps) => {
    if (!menuItems?.length && !profileInfo) return null;

    return (
        <Popover
            raw
            classNameWrapper="z-30 flex flex-col"
            buttonClass="transition rounded-button hover:ring hover:ring-button-header-ring"
            buttonContent={profileInfo ? (
                <SkeletonWrapper
                    isLoading={profileInfo.isLoading ?? false}
                    Skeleton={<SkeletonLine height={8} width={8} className="rounded-button" />}
                >
                    <span className="sr-only">Profile options</span>
                    <Avatar
                        alt={profileInfo.name}
                        src={profileInfo.src}
                        withAltBackground={profileInfo.withAltBackground}
                        favorite={isFavorite}
                    />
                </SkeletonWrapper>
            ) : popoverButtonContent}
            origin={origin}
            className={classNames(
                '-mx-2 pt-2',
            )}
        >
            {({ close }) => (
                <ProfileInfo
                    classNamePanel="bg-white rounded-content shadow-popover shadow-lg ring-1"
                    profileInfo={withProfileInfo ? profileInfo : null}
                    menuItems={menuItems}
                    avatarSize={avatarSize}
                    closePopover={close}
                />
            )}
        </Popover>
    );
};

export default ProfileMenu;
