import React from 'react';
import DOMPurify from 'dompurify';

export const sanitizeRender = (text = '') => (
    DOMPurify.sanitize(text)
);

type HTMLRenderProps = {
    text?: string,
    as?: React.ElementType
} & React.HTMLAttributes<'div'>

type HTMLHighlighRenderProps = {
    text: string,
    searchWords: string[],
    highlightTag?: string,
    caseSensitive?: boolean,
    highlightClass?: string,
    as?: React.ElementType
} & React.HTMLAttributes<'div'>

export const HTMLRender = ({
    text,
    as: Tag = 'div',
    ...props
}: HTMLRenderProps) => (
    <Tag dangerouslySetInnerHTML={{ __html: sanitizeRender(text) }} {...props} />
);

export const HTMLHighlighRender = ({
    text,
    searchWords,
    highlightTag = 'span',
    caseSensitive = false,
    highlightClass = 'font-bold',
    as: Tag = 'div',
    ...props
}: HTMLHighlighRenderProps) => {
    const template = `<${highlightTag} class='${highlightClass}'>$1</${highlightTag}>`;
    const regex = new RegExp(`(${searchWords.join('|')})`, `g${caseSensitive ? '' : 'i'}`);
    const highlightedHtml = text.replace(regex, template);

    return (HTMLRender({ text: highlightedHtml, as: Tag, ...props }));
};
