export const serviceConfig = {
    withBearerApplicationAccessToken: globalThis.serviceConfig?.withBearerApplicationAccessToken,
    GATEWAY_API_URL: globalThis.serviceConfig?.GATEWAY_API_URL,
    SERVICES_URL_SUFFIX: globalThis.serviceConfig?.SERVICES_URL_SUFFIX,
    DMS_URL_SUFFIX: globalThis.serviceConfig?.DMS_URL_SUFFIX,
    CUSTOM_URL_SUFFIX: globalThis.serviceConfig?.CUSTOM_URL_SUFFIX,
    ENGAGEMENT_CONTENT_URL_SUFFIX: globalThis.serviceConfig?.ENGAGEMENT_CONTENT_URL_SUFFIX,
    CLIENT_SERVICE_URL_SUFFIX: globalThis.serviceConfig?.CLIENT_SERVICE_URL_SUFFIX,
    FIDENTITY_URL_SUFFIX: globalThis.serviceConfig?.FIDENTITY_URL_SUFFIX,
    CLIENT_SERVICE_MEMBER_URL_SUFFIX: globalThis.serviceConfig?.CLIENT_SERVICE_MEMBER_URL_SUFFIX,
    headers: globalThis.serviceConfig?.headers,
    CLIENT_SERVICE_HEADERS: globalThis.serviceConfig?.CLIENT_SERVICE_HEADERS,
    isVersionIncluded: globalThis.serviceConfig?.isVersionIncluded,
    GQL_URL_SUFFIX: globalThis.serviceConfig?.GQL_URL_SUFFIX,
};
