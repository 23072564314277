import { xml2js } from 'xml-js';

const isXML = (xmlString: string) => xmlString.slice(0, 1) === '<';

export const xmlToJson = (xmlString?: string, parentPath?: string) => {
    if (!xmlString || !isXML(xmlString)) {
        return xmlString;
    }

    const parsedObject = xml2js(xmlString, { compact: true, textKey: 'text' });

    if (parentPath) {
        return parsedObject?.[parentPath];
    }

    return parsedObject;
};
