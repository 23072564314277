export const createStoreManager = (storeKey = 'clientStore') => {
    const getAll = (): Record<string, any> => {
        try {
            return JSON.parse(window?.sessionStorage?.getItem(storeKey) || 'null') || {};
        } catch {
            return {};
        }
    };
    const get = (key = ''): Record<string, any> => (
        getAll()?.[key] || {}
    );

    const set = (key: string, value: Record<string, any>) => {
        const prevData = getAll();

        try {
            window.sessionStorage.setItem(storeKey, JSON.stringify({
                ...prevData,
                [key]: value,

            }));
        } catch (err) {
            console.error('cannot save, reason:', err);
        }
    };

    const clear = () => {
        window.sessionStorage.removeItem(storeKey);
    };

    return ({
        get,
        getAll,
        set,
        clear,
    });
};
