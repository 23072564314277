import { Decimal } from 'decimal.js';

const isValidNumbers = (...numbers) => (
    numbers.every((number) => !Number.isNaN(+number))
);

export class PreciseCalc {
    static sum(a: number, b: number) {
        if (!isValidNumbers(a, b)) return NaN;

        return +Decimal.sum(+a, +b).valueOf();
    }

    static sub(a: number, b: number) {
        if (!isValidNumbers(a, b)) return NaN;

        return +Decimal.sub(+a, +b).valueOf();
    }

    static mul(a: number, b: number) {
        if (!isValidNumbers(a, b)) return NaN;

        return +Decimal.mul(+a, +b).valueOf();
    }

    static div(a: number, b: number) {
        if (!isValidNumbers(a, b)) return NaN;

        return +Decimal.div(+a, +b).valueOf();
    }
}
