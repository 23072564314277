import React from 'react';
import HeaderWrapper from './HeaderWrapper';

type MobileBackHeaderProps = {
    backLinkSlot?: React.ReactNode | React.ReactNode[] | null,
    titleSlot?: React.ReactNode | React.ReactNode[] | null,
    actionSlot?: React.ReactNode | React.ReactNode[] | null,
    headerWrapperClassName?: string,
}
const MobileBackHeader = ({
    backLinkSlot = null,
    titleSlot = null,
    actionSlot = null,
    headerWrapperClassName = '',
}: MobileBackHeaderProps) => (
    <HeaderWrapper headerClassName={headerWrapperClassName}>
        <div className="flex-1 grid grid-cols-3 h-full items-center">
            <div className="justify-self-start">
                {backLinkSlot}
            </div>
            <div className="justify-self-center font-bold w-max">
                {titleSlot}
            </div>
            {actionSlot && (
                <div className="justify-self-justify-self-end">
                    {actionSlot}
                </div>
            )}
        </div>
    </HeaderWrapper>
);

export default MobileBackHeader;
