import { LogLevel } from '@microsoft/signalr';

const DEBUG_LEVEL = process.env.NODE_ENV === 'development'
    ? LogLevel.Information
    : LogLevel.Critical;

export const notificationConfig = {
    url: globalThis.notificationConfig?.url,
    debugLevel: DEBUG_LEVEL,
    autoReconnectTimeout: globalThis.notificationConfig?.autoReconnectTimeout,
};
