export const config = {
    authority: globalThis.idenityServerConfig?.authority,
    client_id: globalThis.idenityServerConfig?.client_id,
    response_type: globalThis.idenityServerConfig?.response_type,
    scope: globalThis.idenityServerConfig?.scope,
    acr_values: globalThis.idenityServerConfig?.acr_values,
    redirect_uri: globalThis.idenityServerConfig?.redirect_uri,
    post_logout_redirect_uri: globalThis.idenityServerConfig?.post_logout_redirect_uri,
    silent_redirect_uri: globalThis.idenityServerConfig?.silent_redirect_uri,
    response_mode: globalThis.idenityServerConfig?.response_mode,
    tenant_uid: globalThis.idenityServerConfig?.tenant_uid,
    automaticSilentRenew: globalThis.idenityServerConfig?.automaticSilentRenew,
};
