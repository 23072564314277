import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';

type SeparatorProps = {
    as?: React.ElementType,
    margin?: string
    className?: string
}

const ProfileSeparator = (props: SeparatorProps) => {
    const TagName = props.as || 'div';

    const baseClasses = 'border-t border-content-separator w-full';
    const marginClass = props.margin ? props.margin : 'my-3';

    return (
        <>
            {props.as
                ? <hr className={classNames(baseClasses, marginClass, props.className)} />
                : <TagName className={classNames(baseClasses, marginClass, props.className)} role="separator" />}
        </>
    );
};

export default ProfileSeparator;
