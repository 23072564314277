import { act, waitFor, waitForOptions } from '@testing-library/react';

// export function waitFor<T>(
//     callback: () => Promise<T> | T,
//     options?: waitForOptions,
// ): Promise<T>
//
// export const waitForHandler = async (callback) => (
//     act(async () => waitFor(callback))
// );

export function waitForHandler<T>(
    callback: () => Promise<T> | T,
    options?: waitForOptions,
): Promise<T> {
    return act(async () => waitFor<T>(callback, options));
}
