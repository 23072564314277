import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
// import { useDirection } from '@hydrogen/elements.ui.theme';
import { v4 as uuidv4 } from 'uuid';
import { AvatarSizesEnum } from '@hydrogen/elements.core.contracts';
import NavLinkList from './components/NavLinkList';
import Logo from './components/Logo';
import MobileNavBar from './components/MobileNavBar';
import ProfileMenu from './components/ProfileMenu';
import NotificationButton from './components/NotificationButton';
import MetaNavWrapper from './components/MetaNavWrapper';
import HeaderWrapper from './components/HeaderWrapper';
import {
    MenuItemType,
    NavLinkType,
    ProfileInfoType,
    NotificationInfoType,
} from './types';

type HeaderProps = {
    navigations?: NavLinkType[]
    profileInfo?: ProfileInfoType | null
    subHeader?: React.ReactNode | React.ReactNode[] | null,
    menuItems?: MenuItemType[],
    logoSrc?: string | null,
    logoWrapperClassName?: string,
    logoLink?: string,
    logoText?: string,
    navWrapperClassName?: string,
    headerWrapperClassName?: string,
    logoTextClassName?: string,
    logoSvg?: React.ReactElement | null,
    notificationInfo?: NotificationInfoType
    actions?: React.ReactElement[]
    profileMenuButtonContent?: React.ReactElement
    actionsLeft?: React.ReactElement[]
    withMobileMenu?: boolean
    hideNavBarsMobileView?: boolean
    menuAvatarSize?: keyof typeof AvatarSizesEnum
    onClickLogo?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const Header = ({
    subHeader = null,
    profileInfo = null,
    navigations = [],
    menuItems = [],
    logoSrc = null,
    logoSvg = null,
    menuAvatarSize,
    logoLink = '',
    logoWrapperClassName = '',
    logoTextClassName = '',
    navWrapperClassName = '',
    headerWrapperClassName = '',
    logoText,
    notificationInfo,
    actions = [],
    actionsLeft = [],
    withMobileMenu = true,
    hideNavBarsMobileView = false,
    onClickLogo,
    profileMenuButtonContent,
}: HeaderProps) => (
    <HeaderWrapper subHeader={subHeader} headerClassName={headerWrapperClassName}>
        <>
            {withMobileMenu && (
                <div className={classNames('flex items-center lg:hidden')}>
                    <MobileNavBar
                        profileInfo={profileInfo}
                        navigations={navigations}
                        menuItems={menuItems}
                        logoSrc={logoSrc}
                        logoSvg={logoSvg}
                        logoLink={logoLink}
                        onClickLogo={onClickLogo}
                    />
                </div>
            )}
            <div className={classNames(logoWrapperClassName, 'flex items-center')}>
                <Logo
                    onClick={onClickLogo}
                    logoText={logoText}
                    logoSrc={logoSrc}
                    logoSvg={logoSvg}
                    link={logoLink}
                    logoTextClassName={logoTextClassName}
                />
            </div>
            <div className={classNames(
                'w-full flex-1',
                (withMobileMenu || hideNavBarsMobileView) && 'lg:block hidden',
            )}
            >
                <NavLinkList navigations={navigations} />
            </div>
            <MetaNavWrapper className={navWrapperClassName}>
                <>
                    {actions.map((action) => (
                        <React.Fragment key={uuidv4()}>{action}</React.Fragment>
                    ))}
                    {notificationInfo ? (
                        <NotificationButton
                            title={notificationInfo?.title}
                            variant={notificationInfo?.variant}
                            notifications={notificationInfo?.list}
                            isLoading={notificationInfo?.isLoading}
                            markAsRead={notificationInfo?.markAsRead}
                        />
                    ) : <></>}
                    <ProfileMenu
                        profileInfo={profileInfo}
                        menuItems={menuItems}
                        avatarSize={menuAvatarSize}
                        popoverButtonContent={profileMenuButtonContent}
                    />
                    {actionsLeft.map((action) => (
                        <React.Fragment key={uuidv4()}>{action}</React.Fragment>
                    ))}
                </>
            </MetaNavWrapper>
        </>
    </HeaderWrapper>
);

export default Header;
