type ConfigureType<Store, Selectors> = {
    store: Store
    selectors: Selectors
}

type DefaultSelectorsType<S> = { [key: string]: (store: S) => any }

type SelectorsStateType<T extends DefaultSelectorsType<any>> = { [P in keyof T]: ReturnType<T[P]> }

export function generateSelectors<Store = { [key: string]: unknown },
    Selectors extends DefaultSelectorsType<Store> = DefaultSelectorsType<Store>>({
    store,
    selectors,
}: ConfigureType<Store, Selectors>): SelectorsStateType<Selectors> {
    const selectorsState = <SelectorsStateType<Selectors>>{};

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const selector in selectors) {
        selectorsState[selector] = selectors[selector](store as Store);
    }

    return selectorsState;
}
