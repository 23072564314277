import React, {
    useMemo, useRef,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getNavLink } from '@hydrogen/elements.ui.configs';
import { classNames, globalColorClasses } from '@hydrogen/elements.ui.utils';
import Badge from '@hydrogen/elements.ui.components.badge';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { NavLinkType } from '../types';
import { useHiddenElements } from '../hooks/useHiddenElements';
import LinkMore from './LinkMore';

type NavLinkListProps = {
    navigations?: NavLinkType[]
    mainNav?: boolean
}

type NavIconsProps = {
    isActive?: boolean
    icon?: React.ComponentType<{
        className?: string
    }>,
    iconActive?: React.ComponentType<{
        className?: string
    }>,
}
const NavIcons = ({
    isActive = false,
    icon,
    ...props
}: NavIconsProps) => {
    if (!icon) return null;
    const iconActive = props.iconActive || icon;

    return (
        <Icon
            icon={(
                isActive
                    ? iconActive
                    : icon
            )}
            size={IconsSizes.large}
        />
    );
};
const NavLinkList = ({ navigations = [], mainNav = true }: NavLinkListProps) => {
    const NavLink = getNavLink();
    const links = useMemo(() => (
        navigations.map((item) => ({ ...item, id: uuidv4() }))
    ), [navigations]);

    const wrapper = useRef<HTMLDivElement>(null);
    const hiddenElements = useHiddenElements(wrapper, [links]);
    const hidden = useMemo(
        () => (hiddenElements?.map((e) => e?.id)),
        [hiddenElements],
    );
    const linksMore = useMemo(
        () => links.filter((item) => hidden?.includes(item.id)),
        [links, hidden],
    );

    return (
        <div
            ref={wrapper}
            className={classNames(
                'relative  flex-1 gap-2 justify-center items-center flex flex-nowrap  xl:gap-4',
            )}
        >
            {links.map((item) => (
                <NavLink
                    end={!mainNav}
                    to={item.link}
                    key={item.id}
                    id={item.id}
                    className={({ isActive }) => classNames(
                        hidden?.includes(item.id) ? 'hidden' : 'flex gap-1',
                        (isActive || (
                            item.withNested && window.location.pathname.includes(item.link))
                        ) ? 'bg-button-header-background text-header-text-hover-active'
                            : 'text-header-text hover:bg-button-header-background hover:text-header-text-hover-active',
                        'px-2.5  py-1.5 rounded-button text-sm font-bold transition',
                        globalColorClasses.focus,
                    )}
                >
                    {({ isActive }) => (
                        <>
                            <NavIcons
                                isActive={isActive}
                                icon={item.icon}
                                iconActive={item.iconActive}
                            />
                            <span className="whitespace-nowrap ">
                                {item.label}
                                {item.info && <Badge className="ms-2" type="accent" title={<>{item.info}</>} />}
                            </span>
                        </>
                    )}
                </NavLink>
            ))}
            {hidden.length > 0 && (
                <LinkMore
                    origin="right"
                    hasActive={(
                        linksMore?.some((item) => globalThis.location.href.includes(item.link))
                    )}
                >
                    {linksMore.map((item) => (
                        <NavLink
                            end={!mainNav}
                            to={item.link}
                            key={item.id}
                            id={item.id}
                            className={({ isActive }) => classNames(
                                (isActive || (
                                    item.withNested && window.location.pathname.includes(item.link))
                                ) ? 'bg-button-header-background text-header-text-hover-active'
                                    : 'text-header-text hover:text-header-text-hover-active',
                                'flex gap-1',
                                'text-primary-500 flex px-4 py-3 text-s transition text-start font-medium text-sm hover:bg-misc-50',
                            )}
                        >
                            {({ isActive }) => (
                                <>
                                    <NavIcons
                                        isActive={isActive}
                                        icon={item.icon}
                                        iconActive={item.iconActive}
                                    />
                                    {item.label}
                                </>
                            )}
                        </NavLink>
                    ))}
                </LinkMore>
            )}
        </div>
    );
};

export default NavLinkList;
