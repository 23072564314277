import { Menu } from '@headlessui/react';
import { classNames, globalColorClasses, TransitionHelper } from '@hydrogen/elements.ui.utils';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import React from 'react';

type LinkMoreProps = {
    children: React.ReactNode | React.ReactNode[] | null,
    hasActive: boolean,
    origin?: 'left' | 'right'
}
const LinkMore = ({
    children = null,
    hasActive = false,
    origin,
}: LinkMoreProps) => (
    <Menu as="span" className="flex-shrink-0 relative inline-block text-start">
        {({ open }) => (
            <>
                <Menu.Button
                    className={classNames(
                        hasActive
                            ? 'bg-button-header-background text-header-text-hover-active'
                            : 'text-header-text hover:bg-button-header-background hover:text-header-text-hover-active',
                        'rounded-button text-center px-2.5 py-1.5 text-sm font-bold',
                        globalColorClasses.focus,
                        open ? 'bg-neutral-100' : '',
                    )}
                >
                    <Icon icon={DotsHorizontalIcon} size={IconsSizes.default} />
                </Menu.Button>
                <TransitionHelper origin={origin}>
                    <Menu.Items>
                        {children}
                    </Menu.Items>
                </TransitionHelper>
            </>
        )}
    </Menu>
);

export default LinkMore;
