import {
    getLink, getLocales, useTranslate, getLogoConfig,
} from '@hydrogen/elements.ui.configs';
import { classNames, globalColorClasses } from '@hydrogen/elements.ui.utils';

import React from 'react';
import { twMerge } from 'tailwind-merge';

type LogoProps = {
    logoSrc?: string | null
    title?: string
    size?: string
    logoTextClassName?: string
    className?: string
    link?: string
    logoText?: string
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    logoSvg?: React.ReactElement | null
}

const Logo = (props: LogoProps) => {
    const Link = getLink();
    const { t } = useTranslate();
    const locales = getLocales();
    const logoConfig = getLogoConfig();

    const logoSrc = props.logoSrc ?? logoConfig.logoSrc;
    const logoSvg = props.logoSvg ?? logoConfig.logoSvg;
    const logoText = props.logoText ?? logoConfig.logoText ?? t(locales.LOGO_TEXT);

    return (
        <Link
            to={props.link || ''}
            onClick={props.onClick}
            className={classNames(
                props.className,
                'flex flex-shrink-0 items-center md:justify-center lg:justify-start',
                globalColorClasses.focus,
            )}
        >

            {logoSrc
                ? (
                    <img
                        src={logoSrc}
                        alt={props.title || props.logoText}
                        className={classNames(props.size ? props.size : 'h-8', 'w-auto')}
                    />
                )
                : (
                    <span
                        className={classNames(props.size ? props.size : 'max-h-6 sm:max-h-8', 'flex items-center md:justify-center lg:justify-start')}
                    >
                        {props.logoSvg || (
                            <div className="flex items-center">
                                {logoSvg}
                                <span className={classNames(
                                    twMerge(
                                        'text-sm md:text-base text-logo-text font-bold ms-2 md:ms-4',
                                        props.logoTextClassName,
                                    ),
                                )}
                                >
                                    {logoText}
                                </span>
                            </div>
                        )}
                    </span>
                )}
        </Link>
    );
};

export default Logo;
