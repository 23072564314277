import { classNames } from '@hydrogen/elements.ui.utils';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type MetaNavWrapperProps = {
    width?: string,
    className?: string,
    children: React.ReactNode | React.ReactNode[] | null,

}
const MetaNavWrapper = (props: MetaNavWrapperProps) => (
    <div className={
        classNames(
            twMerge(
                props.width,
                'flex flex-shrink-0 gap-4 justify-end',
                props.className,
            ),
        )
    }
    >
        {props.children}
    </div>
);

export default MetaNavWrapper;
