import {
    useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { getFullWidth } from '@hydrogen/elements.ui.utils';
import { useDirection } from '@hydrogen/elements.ui.theme';

type HiddenElementType = {
    id: string
    offset: number
}
export const useHiddenElements = (
    targetElement: { current?: HTMLDivElement | null },
    deps: unknown[] = [],
): HiddenElementType[] => {
    const direction = useDirection();
    const isRtl = direction === 'rtl';
    const [hiddenElements, setHiddenElements] = useState<HiddenElementType[]>([]);
    const childrenWidth = useRef<HiddenElementType[]>([]);

    const onResizeElement = () => {
        if (!targetElement.current) return;
        const { offsetLeft, offsetWidth } = targetElement.current;

        const fullWrapperWidth = offsetWidth + offsetLeft;
        const wrapperWidth = fullWrapperWidth > window.innerWidth
            ? offsetWidth : offsetWidth + offsetLeft;

        const hiddenChildren = childrenWidth.current.filter((a) => (
            a.offset >= wrapperWidth || a.offset >= window.innerWidth - offsetLeft
        ));

        setHiddenElements(hiddenChildren);
    };

    const getChildOffsets = () => {
        if (!targetElement.current) return [];
        let offsets = !isRtl
            ? Math.max(targetElement.current.offsetLeft, 0) + 40
            : window.innerWidth
            - targetElement.current.offsetWidth - targetElement.current.offsetLeft + 40;

        const children = Array.from(targetElement.current?.childNodes || []) as HTMLElement[];

        return children
            .filter((child) => child.id)
            .map((child) => {
                offsets += getFullWidth(child, !!child.offsetWidth);

                return ({
                    id: child.id,
                    offset: offsets,
                });
            });
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(onResizeElement);

        if (targetElement.current) {
            resizeObserver.observe(targetElement.current);
        }

        return () => {
            if (targetElement.current) resizeObserver.unobserve(targetElement.current);
        };
    }, []);

    useLayoutEffect(() => {
        childrenWidth.current = getChildOffsets();
        onResizeElement();
    }, [...deps, targetElement.current?.offsetLeft]);

    return hiddenElements;
};
